<template>
  <LayoutVisiteur>
    <div class="modal-dialog modal-lg">
      <div class="modal-content border-0 bg-light text-dark" autocomplete="off">
        <div class="modal-header mx-2">
          <h4 class="modal-title text-primary" id="modalLabelAjoutCollab">
            Modification de mes informations
          </h4>
        </div>
        <div class="modal-body">
          <section class="row p-2">
            <form @submit.prevent="confirm">
              <div class="col-6 mb-3">
                <label class="form-label">Nom</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  required
                  v-model="form.first_name_visit"
                />
              </div>
              <div class="col-6 mb-3">
                <label class="form-label">Prénom</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  required
                  v-model="form.last_name_visit"
                />
              </div>
              <div class="modal-footer mx-2">
                <button
                  v-if="loading"
                  class="btn btn-primary"
                  type="button"
                  disabled
                >
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Envoi...
                </button>
                <button v-else type="submit" class="btn btn-primary ms-3">
                  Confirmer
                </button>
              </div>
            </form>
            <div class="modal-header">
              <h4 class="modal-title text-primary" id="modalLabelAjoutCollab">
                Modification de mon mot de passe
              </h4>
            </div>
            <form @submit.prevent="confirmLogin">
              <div class="col-md-6 mb-3">
                <label class="form-label">Mot de passe actuel</label>
                <input
                  type="password"
                  class="form-control"
                  autocomplete="new-password"
                  required
                  v-model="formLogin.old_pwd_visit"
                />
              </div>
              <div class="col-md-6 mb-3"></div>
              <div class="col-md-6 mb-3">
                <label class="form-label">Nouveau mot de passe</label>
                <input
                  type="password"
                  class="form-control"
                  autocomplete="new-password"
                  required
                  v-model="formLogin.pwd_visit"
                />
              </div>
              <div class="col-md-6 mb-3"></div>
              <div class="col-md-6 mb-3">
                <label class="form-label">Confirmer le mot de passe</label>
                <input
                  type="password"
                  autocomplete="off"
                  class="form-control"
                />
              </div>
              <div class="modal-footer mx-2">
                <button
                  v-if="loadingMdp"
                  class="btn btn-primary"
                  type="button"
                  disabled
                >
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Envoi...
                </button>
                <button v-else type="submit" class="btn btn-primary ms-3">
                  Confirmer
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  </LayoutVisiteur>
</template>

<script>
import { updateInfoVisiteur, updateMdpVisiteur } from "../../api/visiteur";
import { success, error } from "../../utils/toast";
import LayoutVisiteur from "../../layout/LayoutVisiteur.vue";
import { decodeToken } from "../../utils/decodeToken";

export default {
  name: "Me",
  components: { LayoutVisiteur },
  computed: {
    me() {
      return this.$store.getters["visiteurStore/me"];
    },
  },
  data() {
    return {
      form: {
        first_name_visit: "",
        last_name_visit: "",
      },
      formLogin: {
        old_pwd_visit: "",
        pwd_visit: "",
      },
      loading: false,
      loadingMdp: false,
    };
  },
  mounted() {
    this.form.first_name_visit = this.me.first_name_visit;
    this.form.last_name_visit = this.me.last_name_visit;
  },
  methods: {
    confirm() {
      this.loading = true;
      updateInfoVisiteur(this.me.id_visiteur, this.form).then((result) => {
        if (result.data.error) {
          this.loading = false;
          error(result.data.error);
        } else {
          localStorage.setItem("token", result.data[1].token);
          try {
            const decodeV = decodeToken(result.data[1].token);
            if (decodeV) {
              //console.log("ok visiteur");
              this.$store.dispatch("visiteurStore/setVisiteur", decodeV);
            }
          } catch (err) {
            //console.log(err);
          }
          this.loading = false;
          success("Modification réussi");
        }
      });
    },
    confirmLogin() {
      this.loadingMdp = true;
      updateMdpVisiteur(this.me.id_visiteur, this.formLogin).then((result) => {
        if (result.data.error) {
          this.loadingMdp = false;
          error(result.data.error);
        } else {
          this.loadingMdp = false;
          success("Modification de mot de passe réussi");
        }
      });
    },
  },
};
</script>
